<template>
  <div
    class="flex flex-v flex-align-center flex-pack-center frame"
    style="height: 100%"
    ref="frame"
    :class="{ mobile: isMobile }"
  >
    <div>
      <div class="login-title">欢迎登录<span style="display: none;">中国</span>家居产业智联网</div>
      <div class="login flex">
        <div class="login-left-bg">
          <img src="../../assets/images/login/denglu_bg.jpg" alt="" />
        </div>
        <div class="login-right flex-1">
          <div class="flex flex-justify-around login-tab">
            <div
              v-bind:class="{ active: activeName == 'account' }"
              @click="activeName = 'account'"
            >
              账号登录
            </div>
            <div
              v-bind:class="{ active: activeName == 'sms' }"
              @click="activeName = 'sms'"
            >
              短信登录
            </div>
          </div>
          <div style="margin-top: 30px">
            <AccountLogin
              v-if="activeName == 'account'"
              @success="onLoginSuccess"
              @register="onRegister"
              @onForget="onForget"
            ></AccountLogin>
            <SmsLogin
              v-if="activeName == 'sms'"
              :phone="phone"
              @success="onLoginSuccess"
              @register="onRegister"
              @onForget="onForget"
            ></SmsLogin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { quit } from "@/service/account";
import AccountLogin from "@/components/login/account.vue";
import SmsLogin from "@/components/login/sms.vue";
export default {
  components: { AccountLogin, SmsLogin },
  data() {
    return {
      activeName: "account",
      phone: null,
      isMobile: false,
    };
  },
  created() {
    if (this.$route.query.type) {
      this.activeName = this.$route.query.type;
      this.phone = this.$route.query.phone;
    }
  },
  mounted() {
    this.isMobile = this.$refs["frame"].offsetWidth < 600;
    window.onresize = () => {
      this.isMobile = this.$refs["frame"].offsetWidth < 600;
    };
    // console.log(decodeURI(this.$route.query.p_url).split('?'));
    // if (this.$route.query.portal) {
    //     quit().then();
    // }
  },
  methods: {
    onLoginSuccess() {
      // 配合对接mes门户
      // if (this.$route.query.p_url) {
      //     let url = decodeURI(this.$route.query.p_url);
      //     if (url.split('?').length>1) {
      //         console.log(url+'&p_token='+this.$store.state.loginUser.token);
      //         // window.open(url+'&p_token='+this.$store.state.loginUser.token,'_self');
      //         window.location.href = url+'&p_token='+this.$store.state.loginUser.token;
      //     } else {
      //         console.log(url+'?p_token='+this.$store.state.loginUser.token);
      //         // window.open(url+'?p_token='+this.$store.state.loginUser.token,'_self');
      //         window.location.href = url+'?p_token='+this.$store.state.loginUser.token;
      //     }
      //     if (this.$route.query.portal) {
      //         quit().then();
      //     }
      // } else {
      //     this.$router.push('/center');
      // }
      // 适配供应商角色
      if (this.$store.state.loginUser.comp.comp_type == 6) {
        this.$router.push("/supplier");
      } else if (this.$store.state.loginUser.comp.comp_type == 7) {
        this.$router.push("/cloudLogistic");
      } else {
        this.$router.push("/center");
      }
    },
    onRegister() {
      if (this.isMobile) {
        this.$router.push("/mobile/register");
      } else {
        this.$router.push("/register");
      }
    },
    onForget() {
      if (this.isMobile) {
        this.$router.push("/mobile/forget");
      } else {
        this.$router.push("/forget");
      }
    },
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>
<style scoped>
.login {
  width: 800px;
  /* height: 560px; */
  align-self: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(200, 207, 228, 1);
}
.login-title {
  text-align: center;
  color: #292929;
  font-size: 24px;
  padding-top: 60px;
  padding-bottom: 80px;
  font-weight: bold;
}
.login-left-bg {
  width: 400px;
  /* height: 100%; */
}
.login-left-bg > img {
  width: 100%;
  height: 100%;
}
.login-right {
  height: 519px;
  padding: 60px 60px;
}
.login-tab > div {
  cursor: pointer;
  font-size: 16px;
  padding: 7px 0;
}
.login-tab > div.active {
  color: #5074ee;
  border-bottom: 2px solid #5074ee;
}
.frame.mobile .login-title {
  display: none;
}
.frame.mobile .login {
  width: auto;
  box-shadow: none;
}
.frame.mobile .login-left-bg {
  display: none;
}
</style>